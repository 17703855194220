import React from "react";
import imgBanner from "../../assets/image/inner-page/png/equipment-banner.png";

const Content9 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        {/* Section title */}
        <div className="row align-items-xl-center justify-content-between">
          <div
            className="col-xl-7 col-lg-6 col-md-10"
            data-aos="fade-right"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <h3 className="font-size-10 mb-0 letter-spacing-n83">
              El equipamiento que necesitan
            </h3>
          </div>
          <div
            className="col-xl-5 col-lg-6 col-md-8"
            data-aos="fade-left"
            data-aos-delay={500}
            data-aos-once="true"
          >
            <p className="font-size-7 mb-0 pr-xl-2 pr-lg-5 pr-md-10 pr-sm-20 mt-7 mt-lg-0">
              Todo lo que necesitan para realizar su jornada de manera efectiva
              desde uniformes hasta material de oficina.
            </p>
          </div>
        </div>
        {/* End Section title */}
        {/* Banner */}
        <div className="row">
          <div className="col-12">
            <div
              className="pt-10 pt-lg-17 rounded-10"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <img className="w-100" src={imgBanner} alt="" />
            </div>
          </div>
        </div>
        {/* End Banner */}
        {/* Services */}
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="square-60 bg-blue-3 shadow-dodger-blue-3 rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-puzzle-piece" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Tipos</h4>
                <p className="font-size-5 mb-0">
                  Configura los tipos de equipamiento que necesites con
                  diferentes.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={700}
              data-aos-once="true"
            >
              <div className="square-60 bg-red shadow-red rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-book-open" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Ofertas</h4>
                <p className="font-size-5 mb-0">
                  Publica ofertas durante un periodo de tiempo.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={900}
              data-aos-once="true"
            >
              <div className="square-60 bg-buttercup shadow-buttercup rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-box-open" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Pedidos</h4>
                <p className="font-size-5 mb-0">
                  Gestiona pedidos a demana fuera de ofertas.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1100}
              data-aos-once="true"
            >
              <div className="square-60 bg-dark-green shadow-dark-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-warehouse" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Almacenes</h4>
                <p className="font-size-5 mb-0">
                  Da de alta tus almaneces para llevar el control de tu
                  inventario.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1300}
              data-aos-once="true"
            >
              <div className="square-60 bg-light-orange shadow-light-orange rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-truck-loading" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Entregas</h4>
                <p className="font-size-5 mb-0">
                  Controla la entrega de pedido a tus empleados/as.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1500}
              data-aos-once="true"
            >
              <div className="square-60 bg-purple-heart shadow-purple-heart rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-undo-alt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Devoluciones</h4>
                <p className="font-size-5 mb-0">
                  Gestiona devoluciones con fácilidad.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1700}
              data-aos-once="true"
            >
              <div className="square-60 bg-bali-gray shadow-bali-gray rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-exchange-alt" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Cambios</h4>
                <p className="font-size-5 mb-0">
                  Gestiona cambios de equipamientos si ha surgido algun
                  problema.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-xs-8">
            <div
              className="pt-13 pt-lg-20 text-center"
              data-aos="fade-up"
              data-aos-delay={1900}
              data-aos-once="true"
            >
              <div className="square-60 bg-neon-green shadow-neon-green rounded-10 text-white font-size-7 mx-auto">
                <i className="fa fa-coins" />
              </div>
              <div className="mt-9">
                <h4 className="font-size-7 mb-5">Monedas</h4>
                <p className="font-size-5 mb-0">
                  Utiliza el sistema monetario de Timeview para un gasto
                  equitativo en la empresa.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Services */}
      </div>
    </div>
  );
};

export default Content9;
